.upload_file{
    min-width: 200px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #67747b ;
    border-radius: 10px;
    color: #868686 ;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}