.content {
    height: calc(100% - 100px);
}

.content::-webkit-scrollbar {
    display: none;
}


.back_drop {
    opacity: 0.5;
}

@media(max-width:768px) {
    .container {
        height: 100% !important;
    }
}