.notificationMenu {
	position: absolute;
	display: flex;
	flex-direction: column;
	right: 0;
	width: 460px;
	height: 305px;
	top: 45px;
	background-color: #ffff;
	overflow-y: auto;
	border-radius: 5px;
	transition: all 0.3s;
	box-shadow: 1px 1px 1px #ddd;
}

.notificationMenu .notification_box {
	padding: 0.5rem 1rem;
}

.notificationMenu .notification_box:hover {
	background-color: #fff;
}

.notificationMenu::-webkit-scrollbar {
	width: 5px;
}

.notificationMenu::-webkit-scrollbar-track {
	background: #fafafa;
}

.notificationMenu::-webkit-scrollbar-thumb {
	background: #02466a;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
}

.notificationMenu::-webkit-scrollbar-thumb:hover {
	background: #0478b7;
}

.notification_desc {
	color: #000;
	width: 190px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

@media (max-width: 768px) {
	.notificationMenu {
		position: fixed;
		left: 0;
		top: 75px;
		max-width: 90%;
		height: 280px;
		margin: auto;
	}

	.notification_desc {
		width: 120px;
	}
}
