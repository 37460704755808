.select {
  height: 100% !important;
}

.select :global(.MuiSelect-icon) {
  top: 15px;
  left: 7px;
  right: unset;
}

.select :global(.MuiSelect-icon path) {
  fill: #242424;
}

@media(max-width:768px) {
  .select :global(.MuiSelect-icon) {
    top: 10px;
  }
}

.tags_boxes {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.tags_boxes .tag{
  display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.2rem;
	padding: 5px;
	background-color: #ededed;
	border-radius: 6px;
}
.tags_boxes .tag span{
  font-size: 1rem;
	font-weight: 500;
	line-height: 18px;
}
.tags_boxes .tag svg{
  width: 1rem;
	height: 1rem;
	cursor: pointer;
}