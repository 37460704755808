.content::-webkit-scrollbar {
	display: none;
}

.back_drop {
	opacity: 0.5;
}

.content::-webkit-scrollbar {
	display: none;
}

.back_drop {
	opacity: 0.5;
}

.select :global(.MuiSelect-icon) {
	top: 15px;
	left: 7px;
	right: unset;
}

.select :global(.MuiSelect-icon path) {
	fill: #242424;
}

.add_new_product :global(.MuiPopover-paper) {
	background-color: #d3d3d3;
}

.keywords :global(.rti--container) {
	max-width: 100%;
	min-height: 192px;
	background-color: #eeeeef;
	border: none;
	display: flex;
	flex-direction: row;
	align-items: baseline;
}

.keywords :global(.rti--container .rti--input) {
	min-width: 195px;
	width: auto;
	height: 30px;
	padding: 5px;
	background-color: #ffffff;
	border: 1px solid #d4d4d4;
	border-radius: 15px;
}

.keywords :global(.rti--container .rti--tag) {
	width: fit-content;
	height: 30px;
	padding: 5px;
	background-color: #ffffff;
	border: 1px solid #d4d4d4;
	border-radius: 15px;
}

.pixels {
	width: 555px;
	max-width: 100%;
	min-height: 160px;
	max-height: 160px;
	overflow: auto;
	background-color: #eeeeef;
	box-shadow: 0px 3px 6px #e622370f;
	direction: ltr;
}

.pixels textarea {
	outline: none;
}

.productEditor :global(.quill) {
	min-width: 555px;
	max-width: 555px;
}
.productEditor :global(.quill .ql-toolbar.ql-snow) {
	background-color: #fbfbfb;
	border: 1px solid #a7a7a780;
}
.productEditor :global(.quill .ql-container.ql-snow) {
	border: 1px solid #a7a7a780 !important;
	border-bottom-left-radius: 0.375rem;
	border-bottom-right-radius: 0.375rem;
	height: 145px !important;
}
.productEditor :global(.quill .ql-container.ql-snow .ql-editor) {
	background-color: #f5f5f5;
	border-bottom-left-radius: 0.375rem;
	border-bottom-right-radius: 0.375rem;
}

@media (max-width: 768px) {
	.container {
		height: 100% !important;
	}

	.keywords :global(.rti--container) {
		width: 100%;
	}

	.pixels {
		width: 100%;
	}
	.productEditor :global(.quill) {
		min-width: 100%;
		max-width: 100%;
	}
}
