.recording_status{
    background-color: #FAFAFA;
}
.filter_con{
    border: 1px solid #1DBBBE;
    display: flex;
    gap: 8px;
    padding: 0.5rem 3rem;
    border-radius: 0.25rem;
    cursor: pointer;
}
.filter_con h2{
    color: #1DBBBE;
}