.arrow_con {
	height: 1.5rem;
	width: 1.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: rgba(2, 70, 106, 0.4);
	cursor: pointer;
}
.date_input :global(.react-datepicker-wrapper input) {
	font-size: 18px;
	cursor: pointer;
}
.date_input :global(.react-datepicker-wrapper input::placeholder) {
	color: #011723;
}
.disabled_date_input :global(.react-datepicker-wrapper input) {
	font-size: 18px;
	color: #a7a7a7;
	background-color: transparent;
}
.disabled_date_input :global(.react-datepicker-wrapper input::placeholder) {
	color: #a7a7a7;
}
.disabled_date_input :global(#icon-date path) {
	fill: #a7a7a7;
}
.icon {
	width: 24px;
	height: 24px;
}
.icon path {
	fill: #1dbbbe;
}
.date_input :global(.react-datepicker-wrapper) {
	width: 100%;
}
