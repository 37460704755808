.content::-webkit-scrollbar {
    display: none;
  }
  

.back_drop{
  opacity: 0.5;
  animation: backDropAnim 0.5s ease-in-out;
}

.password {
  cursor: pointer;
}

.password path,.password ellipse {
  fill: #7C7C7C;
}