.inputs input::placeholder {
    text-align: center;
}

.icon {
    margin-left: 1.2rem;
}

.icon path {
    fill: #02466A;
}

.select {
    height: 100% !important;
    background-color: #fafafa !important;
    color: #67747b !important;
    font-size: 18px !important;
}

.select :global(.MuiSelect-icon) {
    top: 15px;
    left: 7px;
    right: unset;
}

.select :global(.MuiSelect-icon path) {
    fill: #242424;
}

@media(max-width:768px) {
    
    .select :global(.MuiSelect-icon) {
        top: 10px;
    }
}