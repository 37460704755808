.backdrop {
	height: calc(100vh - 80px);
}
.line_anim {
	animation: anim 1s ease-out;
	-webkit-animation: anim 1s ease-out;
}
@keyframes anim {
	from {
		width: 100%;
	}
	to {
		width: 0%;
	}
}
@media (max-width: 768px) {
	.backdrop {
		height: 100% !important;
	}
}
