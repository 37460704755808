.navbar {
	background-color: var(--main-color);
	padding: 1rem 2rem 1rem 6rem;
	display: flex;
	justify-content: space-between;
	position: fixed;
	width: 100%;
	z-index: 9999;
	top: 0;
}
.logo {
	width: calc(240px - 2rem);
}
.menuIcon {
	font-size: 2rem;
	color: #fff;
	cursor: pointer;
}
@media (max-width: 768px) {
	.navbar {
		padding: 1rem 2rem 1rem 2rem;
	}
}
