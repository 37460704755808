.Loading{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999999;
}
.Logo{
    width: 120px;
    height: 120px;
}
.Loading .Logo img{
    width: 100%;
    height: 100%;
    animation: loading 1.5s infinite alternate;
}

@keyframes loading{
    from{
        opacity: 0.4;
        transform: scale(1) ;
    }
    to{
        opacity: 1;
        transform: scale(1.2);
    }
}