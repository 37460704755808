.arrow_con {
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: rgba(2, 70, 106, 0.4);
    cursor: pointer;
}

.editor :global(.quill .ql-toolbar.ql-snow) {
    font-family: "Tajawal", sans-serif !important;
    padding: 1rem !important;
    background-color: #ffffff;
    border: 1px solid #F1F1F1;
    border-radius: 0;
}

.editor :global(.quill .ql-container.ql-snow) {
    min-height: 160px;
    height: auto !important;
    padding: 1rem;
    background-color: #F4F5F7;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border: 1px solid #67747B33;
}

.editor :global(.quill .ql-editor) {
    background-color: transparent !important;
    padding: 0;
    min-height: inherit;
}

.clear_icon {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.clear_icon path {
    fill: #67747B;
}

.arrow_icon path {
    fill: #011723;
}

.tags>div {
    width: 100%;
    background-color: transparent;
    gap: 1rem;
    display: flex !important;
    flex-direction: row;
    align-items: baseline;
}

.tags input {
    min-width: 200px;
    width: auto;
    padding: 5px 10px;
    border-radius: 18px;
}

.tags :global(.rti--tag) {
    font-size: 18px;
    color: #67747B;
    background-color: #EBEBEB;
    padding: 5px 10px;
    border-radius: 18px;
}