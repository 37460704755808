.activity_menu :global(.MuiMenu-paper) {
	background-color: #eff9ff;
	border-radius: 5px;
	box-shadow: none;
}
.search {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	gap: 0.2rem;
	padding: 0 0.5rem;
	height: 45px;
	width: 500px;
	max-width: 100%;
	background-color: transparent;
	border: 1px solid #e0e0e0;
	border-radius: 6px;
}
.search input {
	width: 100%;
	height: 100%;
	background-color: transparent;
	direction: rtl;
	border: none;
	outline: none;
}
