.suggestions{
    display: flex;
    flex-direction: column;
    max-height: 295px;
    overflow-y: auto;
}

.suggestions::-webkit-scrollbar {
    width: 5px;
}

.suggestions::-webkit-scrollbar-track {
    background: #fafafa;
}

.suggestions::-webkit-scrollbar-thumb {
    background: #02466a;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.suggestions::-webkit-scrollbar-thumb:hover {
    background: #0478b7;
}
.suggestions svg path{
    fill: #02466a;
}