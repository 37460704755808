.content::-webkit-scrollbar {
	display: none;
}

.back_drop {
	opacity: 0.5;
}

.add_video {
	width: 100%;
	max-width: 100%;
	min-height: 160px;
	max-height: 160px;
	overflow: auto;
	background-color: #02466a00;
	border: 1px solid #a7a7a7;
	direction: ltr;
	border-radius: 0.375rem;
}

.add_video textarea {
	outline: none;
}
