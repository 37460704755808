.pro {
	background-color: #0099fb;
	padding: 3rem 1rem !important;
	box-shadow: 0px 6px 12px #24242429;
}
.pro h2 {
	color: #fff;
}

.normal {
	background-color: #f7fcff;

	box-shadow: 0px 3px 6px #438bb01a;
}
