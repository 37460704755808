.content::-webkit-scrollbar {
  display: none;
}

.back_drop {
  opacity: 0.5;
}

.arrow_con {
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #02466A1A;
}

.icons {
  width: 24px;
  height: 24px;
}

.icons path {
  fill: #1DBBBE;
}

.editor {
  max-width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #0000000F;
  border-radius: 8px;
}

.editor :global(.quill) {
  padding: 0;
  padding-bottom: 20px;
  border-radius: 8px;
}

.editor :global(.quill .ql-toolbar.ql-snow) {
  padding: 20px !important;
  border: 1px solid #67747b33;
  border-radius: 8px !important;
}

.editor :global(.quill .ql-container.ql-snow) {
  height: auto;
  border: none !important;
}

@media(max-width:768px) {
  .container {
    height: 100% !important;
  }
}