.EmailMenu{
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0;
    width: 450px;
    height: 275px;
    top: 45px;
    background-color: #fafafa;
    overflow-y:auto;
    border-radius: 5px; 
    transition: all 0.3s;
    box-shadow: 1px 1px 1px #ddd;
}
.EmailMenu .email_box{
    padding: 0.5rem 1rem;
}
.EmailMenu .email_box:hover{
    background-color: #fff;
}
.EmailMenu::-webkit-scrollbar {
    width: 5px;
}
.EmailMenu::-webkit-scrollbar-track {
    background: #fafafa;
}
.EmailMenu::-webkit-scrollbar-thumb {
    background: #02466a;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.EmailMenu::-webkit-scrollbar-thumb:hover {
    background: #0478b7;
}
@media(max-width:768px){
    .EmailMenu{
        position: fixed;
        left: 0;
        top: 75px;
        max-width: 90%;
        height: 220px;
        margin: auto;
    }
}