.editor :global(.quill) {
    width: 100%;
    background: #FAFAFA;
}

.editor :global(.quill .ql-toolbar.ql-snow) {
	font-family: "Tajawal", sans-serif !important;
	padding: 1rem !important;
    background-color: #ffffff;
    border: 1px solid #F1F1F1;
}

.editor :global(.quill .ql-container.ql-snow) {
    background-color: #FAFAFA;
    height: fit-content !important;
    max-height: 7rem;
    border: none !important;
    overflow: auto;
}

.editor :global(.quill .ql-editor){
    background-color: #FAFAFA !important;
}