.backdrop{
    height: calc( 100vh - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
/* .line_anim
{
    animation: anim 3s ease-out;
    -webkit-animation: anim 3s ease-out;
}
@keyframes anim {
    from{
        width: 100%;
    }
    to{
        width: 0%;
    }
} */
@media(max-width:768px){
    .backdrop{
      height: 100% !important;
    }
}