.keywords :global(.rti--container) {
    width: 100%;
    min-height: 192px;
    background-color: #EEEEEF;
    box-shadow: 0px 3px 6px #E622370F;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.keywords :global(.rti--container .rti--input) {
    min-width: 195px;
    width: auto;
    height: 30px;
    padding: 5px;
    background-color: #ffffff;
    border: 1px solid #D4D4D4;
    border-radius: 15px;
}

.keywords :global(.rti--container .rti--tag) {
    width: fit-content;
    height: 30px;
    padding: 5px 10px;
    background-color: #ffffff;
    border: 1px solid #D4D4D4;
    border-radius: 15px;
}

.pixels {
    width: 100%;
    min-height: 160px;
    max-height: 160px;
    overflow: auto;
    background-color: #EEEEEF;
    box-shadow: 0px 3px 6px #E622370F;
    direction: ltr;
}

.pixels textarea {
    outline: none;
}