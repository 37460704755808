.editor :global(.quill) {
    width: 100%;
    background: transparent;
    border: 1px solid #67747b33;
    border-radius: 4px;
    padding: 0.25rem;
    display: flex;
    flex-direction: column-reverse;
}

.editor :global(.quill .ql-toolbar.ql-snow) {
    background-color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    font-size: 15px;
    justify-content: flex-start;
	font-family: "Tajawal", sans-serif !important;
	padding: 6px 5px !important;
    border: 1px solid #F1F1F1;
    margin-bottom: 5px;
    border-radius: 2px;
}

.editor :global(.quill .ql-container.ql-snow) {
    background-color: transparent;
    height: fit-content !important;
    max-height: 7rem;
    border: none !important;
    overflow: auto;
}

.editor :global(.quill .ql-editor){
    background-color: transparent !important;
}