
.row-per-page-box{
    position: relative;
    padding: 4px 16px;
    border: 1px solid #1bbabd;
    border-radius: 6px;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #1bbabd;
    font-weight: 500;
	gap: 12px;
}
.row-per-page-box i{
    width: 45px;
    height: 45px;
    display: inline-flex;
    background: #63e5e2;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    color: #FFF;
}
.row-per-page-box svg{
    width: 24px;
    height: 24px;
    fill: #FFF;
}
.row-per-page-box .MuiOutlinedInput-root{
	position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
}
.btn-container-row{
	display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    direction: ltr;
	gap: 30px;
    margin-top: 30px;
}
.MuiPagination-ul{

}
.MuiPagination-ul li button{
    color: #02466a;
    font-weight: 600;
}
.MuiPagination-ul li button.Mui-selected,
.MuiPagination-ul li button.Mui-selected:hover,
.MuiPagination-ul li button:hover{
    background: #1bbabd;
    color: #FFF;
}
@media (max-width: 575px){
	.btn-container-row{
        flex-direction: column;
	}
}