/* @import "rsuite/dist/rsuite.min.css"; */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;500;600;700&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");

:root {
	--main-color: #02466a;
	--sec-color: #1dbbbe;
	--third-color: #b6be34;
}

body:has(.MuiPopover-root) {
	overflow: auto !important;
	padding-right: 0 !important;
}

::-webkit-scrollbar {
	width: 0.7em;
}

::-webkit-scrollbar-thumb {
	background-color: #085c7ba6;
	background-clip: content-box;
	border: 2px solid transparent;
	border-radius: 100px;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	-ms-border-radius: 100px;
	-o-border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #085c7b;
}

.fcc {
	display: flex;
	justify-content: center;
	align-items: center;
}

.fbc {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	font-family: "Tajawal", sans-serif !important;
}

body {
	margin: 0;
	font-family: "Tajawal", sans-serif !important;
}

.text-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

h2 {
	color: #011723;
}

/* main_page */
.main_page_date_picker .ant-picker-range-separator,
.main_page_date_picker .ant-picker-active-bar {
	display: none;
}

.coupon_page_date_picker .ant-picker-range-separator,
.coupon_page_date_picker .ant-picker-active-bar {
	display: none;
}

.coupon_page_date_picker.ant-picker-disabled {
	background-color: transparent;
}

.coupon_page_date_picker input {
	cursor: pointer;
}

.ant-picker-cell.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell.ant-picker-cell-range-end .ant-picker-cell-inner {
	background: #0bf1d1 !important;
}

/* soqu */

.MuiPopover-root ul:has(.souq_storge_category_filter_items) {
	padding: 0 !important;
}

.MuiPopover-root ul:has(.souq_storge_section_filter_items) {
	padding: 0;
}

.MuiPopover-paper:has(.multiple_select) {
	border-radius: 0;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.MuiPopover-root ul:has(.multiple_select) {
	background-color: #67747b33;
}

.multiple_select:hover {
	background-color: #b4edee !important;
}

.multiple_select.Mui-selected {
	background-color: #1dbbbe !important;
}

.multiple_select.Mui-selected .Mui-checked {
	color: #fff;
}

/* .MuiMenuItem-root:hover{
  background-color: #02466A !important;
} */
.otlobha_filtering_sec .MuiPaper-root {
	display: none !important;
}

.otlobha_new_product .rti--container {
	width: 555px;
	border: 1px solid rgba(167, 167, 167, 0.5);
	padding: 1rem !important;
}

.rti--container:focus-within {
	border: 1px solid rgba(167, 167, 167, 0.5) !important;

	box-shadow: none !important;
}

.rti--container:focus {
	border: none !important;
}

/* /////////////////// acadmic*/
.demo-wrapper {
	border: 1px solid #67747b33;
	border-radius: 4px;
	padding: 0.25rem;
}

.rdw-editor-main {
	height: 100%;
	max-height: 11rem;
}

.otlobha_acadmic .rti--container {
	border: 1px solid rgba(167, 167, 167, 0.5);
	padding: 1rem !important;
	min-height: 160px;
	display: block;
}

.otlobha_acadmic .rti--container .rti--input {
	width: 5rem;
}

.trader_alert .demo-editor {
	height: fit-content !important;
}

/* trader_alert */
.trader_alert .demo-wrapper {
	display: flex;
	flex-direction: column-reverse;
}

.trader_alert .demo-wrapper .public-DraftEditorPlaceholder-inner {
	color: #011723;
	font-weight: 600;
	font-size: 1rem;
}

/* pages page  */
.add_new_page_popup .rdw-editor-toolbar {
	background-color: #fafafa;
	border: 1px solid #fafafa;
}

.add_new_page_popup .demo-wrapper {
	border: 1px solid #d3d3d3;
}

.add_new_page_popup .rdw-editor-main {
	height: 30rem !important;
	padding: 1.25rem;
	background-color: #fff;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

@media (max-width: 767px) {
	.ant-picker-panels {
		flex-direction: column;
	}

	.add_new_page_popup .rdw-editor-main {
		height: 100% !important;
	}

	::-webkit-scrollbar {
		width: 0.4em;
	}

	::-webkit-scrollbar-thumb {
		border: 1px solid transparent;
	}
}

@media print {
	.souq-order-details {
		direction: rtl;
	}

	.souq-order-details .box-card .wrapper {
		display: flex;
		flex-direction: row !important;
		align-items: flex-end !important;
	}

	.souq-order-details .box-card .wrapper > div {
		width: 35%;
		flex: 1;
	}

	.souq-order-details .order-details .boxes {
		display: flex;
		flex-direction: row !important;
		align-items: flex-end !important;
	}
	.souq-order-details .order-details .boxes > div {
		width: 18% !important;
	}

	.souq-order-details .clint-details .boxes {
		display: flex;
		flex-direction: row !important;
		align-items: center !important;
	}

	.souq-order-details .clint-details .boxes > div {
		max-width: 48.5% !important;
	}

	.hide-me-in-print-media {
		display: none !important;
	}
}
