.icons path {
    fill: #ADB5B9;
}

.password {
    cursor: pointer;
}

.password path,.password ellipse {
    fill: #7C7C7C;
}