
.back_drop{
  opacity: 0.5;
  animation: backDropAnim 0.5s ease-in-out;
}

.select :global(.MuiSelect-icon) {
  top: 15px;
  left: 7px;
  right: unset;
  opacity: 0;
}

.select :global(.MuiSelect-icon path) {
  fill: #1DBBBE;
}
.select :global(.MuiSelect-iconOpen.MuiSelect-icon){
  opacity: 1;
}
.select:has(:global(.MuiSelect-iconOpen.MuiSelect-icon)){
  background-color: #fff;
}
.select:has(:global(.MuiSelect-iconOpen.MuiSelect-icon)) :global(h2){
  color: #1DBBBE;
}

.password {
  cursor: pointer;
}

.password path,.password ellipse {
  fill: #7C7C7C;
}
@media(max-width:768px){
  .container{
    height: 100% !important;
  }
}