.editor :global(.quill) {
	width: 100%;
	background: #fafafa;
}

.editor :global(.quill .ql-toolbar.ql-snow) {
	font-family: "Tajawal", sans-serif !important;
	padding: 1rem !important;

	border: 1px solid #f1f1f1;
}

.editor :global(.quill .ql-container.ql-snow) {
	background-color: #fafafa;
	height: fit-content !important;
	max-height: 7rem;
	border: none !important;
	overflow: auto;
}

.editor :global(.quill .ql-editor) {
	height: 200px;
}
