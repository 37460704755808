.sidebar{
    position: fixed!important;
    top: 80px;
    width: 240px;
    padding: 1.8rem 1rem 3.6rem ;
    background-color: rgba(240,247,251);
    font-family: 'Tajawal', sans-serif !important;
    height: 100%;
    overflow:auto;
    padding-bottom: 100px; 
}
.sidebar .Mui-selected 
{
    background-color: red;
}
.number{
    display: none;
}
@media (max-width:992px){
    .sidebar{
        top: 75px;
    }
}
@media (max-width:768px){
    .sidebar{
        position: fixed;
        z-index: 20;
        right: -100%;
    }
    .sidebar.active{
        right: 0;
        z-index: 99;
    }
}