.content::-webkit-scrollbar {
  display: none;
}

.back_drop {
  opacity: 0.5;
}

.variety_number::placeholder {
  color: #0099FB;
}

@media(max-width:768px) {
  .container {
    height: 100% !important;
  }
}