.app-page {
	left: 0;
	top: 80px;
	width: calc(100% - 240px);
	position: fixed;
	height: 100%;
	overflow: auto;
	padding-bottom: 100px !important;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@media (max-width: 768px) {
	.app-page {
		width: 100%;
		padding: 0 !important;
		position: absolute;
	}
}

.img_icons {
	height: 40px;
	max-width: 40px;
	min-width: 40px;
	max-height: 40px;
	min-height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #dddddd;
	object-fit: scale-down;
	overflow: hidden;
	padding: 4px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
}

.custom-paginate {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: center;
	gap: 15px;
}

.custom-paginate li {
	width: 24px;
	height: 24px;
	font-size: 1rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	border-radius: 0.5rem;
}

.custom-paginate li a {
	font-weight: 500;
	color: #000000;
}

.custom-paginate .previous,
.custom-paginate .next {
	background-color: transparent;
	width: auto;
	height: auto;
}

.custom-paginate .previous a,
.custom-paginate .next a {
	color: #000000;
	font-size: 1.1rem;
	transform: rotate(180deg);
}

.custom-paginate .disabled a {
	display: none;
}

.custom-paginate .selected {
	background-color: #508ff4;
}

.custom-paginate .selected a {
	color: #ffffff;
}

.MuiAccordionSummary-content.Mui-expanded {
	margin: 0 !important;
}
