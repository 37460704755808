.Accordions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.Accordions :global(.MuiAccordion-root) {
    background-color: #ededef;
    box-shadow: none;
}

.Accordions :global(.MuiAccordion-root.Mui-expanded) {
    margin-top: 0;
    margin-bottom: 0;
}


.Accordions :global(.MuiAccordion-root .MuiAccordionSummary-root) {
    display: flex;
    flex-direction: row-reverse;
}

.Accordions :global(.MuiAccordionSummary-content) {
    margin: 12px 0;
}

.Accordions :global(.MuiAccordion-root .MuiAccordionSummary-root.Mui-expanded) {
    min-height: 48px;
    background-color: #59cfd1a3;
}

.Accordions :global(.MuiAccordion-root .MuiAccordionDetails-root) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.OptionValue :global(.MuiToggleButton-root) {
    padding: 2px;
    border-radius: 50%;
}

.OptionValue :global(.MuiToggleButton-root.Mui-selected) {
    color: #ffffff;
    background-color: var(--sec-color);
}

.OptionValue :global(.MuiToggleButton-root.Mui-selected:hover) {
    background-color: #03acaf;
}

.OptionValue :global(.MuiToggleButton-root svg) {
    width: 0.7em;
    height: 0.7em;
}

@media (max-width: 768px) {
    .Accordions :global(.MuiAccordion-root .MuiAccordionSummary-root) {
        padding: 0 5px;
    }

    .Accordions :global(.MuiAccordion-root .MuiAccordionDetails-root) {
        padding: 8px;
    }
}