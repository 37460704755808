.textEditor :global(.quill) {
	width: 100%;
	height: 100%;
}

.textEditor :global(.quill .ql-container.ql-snow) {
	height: 15rem;
	border: 1px solid #dddddd;
}

.textEditor :global(.quill .ql-snow .ql-picker.ql-header) {
	width: max-content;
}

.textEditor :global(.quill .ql-toolbar.ql-snow .ql-picker-label) {
	border: none;
}

.textEditor :global(.quill .ql-snow .ql-header .ql-picker-label) {
	padding-left: 8px;
	padding-right: 16px;
}

.textEditor :global(.quill .ql-editor p),
.textEditor :global(.quill .ql-editor ol),
.textEditor :global(.quill .ql-editor ul),
.textEditor :global(.quill .ql-editor pre),
.textEditor :global(.quill .ql-editor blockquote),
.textEditor :global(.quill .ql-editor h1),
.textEditor :global(.quill .ql-editor h2),
.textEditor :global(.quill .ql-editor h3),
.textEditor :global(.quill .ql-editor h4),
.textEditor :global(.quill .ql-editor h5),
.textEditor :global(.quill .ql-editor h6) {
	white-space: normal;
}

.textEditor :global(.quill .ql-snow .ql-picker.ql-expanded .ql-picker-label),
.textEditor :global(.quill .ql-snow.ql-toolbar button:hover),
.textEditor :global(.quill .ql-snow .ql-toolbar button:hover),
.textEditor :global(.quill .ql-snow.ql-toolbar button:focus),
.textEditor :global(.quill .ql-snow .ql-toolbar button:focus),
.textEditor :global(.quill .ql-snow.ql-toolbar button.ql-active),
.textEditor :global(.quill .ql-snow .ql-toolbar button.ql-active),
.textEditor :global(.quill .ql-snow.ql-toolbar .ql-picker-label:hover),
.textEditor :global(.quill .ql-snow .ql-toolbar .ql-picker-label:hover),
.textEditor :global(.quill .ql-snow.ql-toolbar .ql-picker-label.ql-active),
.textEditor :global(.quill .ql-snow .ql-toolbar .ql-picker-label.ql-active),
.textEditor :global(.quill .ql-snow.ql-toolbar .ql-picker-item:hover),
.textEditor :global(.quill .ql-snow .ql-toolbar .ql-picker-item:hover),
.textEditor :global(.quill .ql-snow.ql-toolbar .ql-picker-item.ql-selected),
.textEditor :global(.quill .ql-snow .ql-toolbar .ql-picker-item.ql-selected),
.textEditor :global(.quill .ql-snow.ql-toolbar button:hover .ql-stroke),
.textEditor :global(.quill .ql-snow .ql-toolbar button:hover .ql-stroke),
.textEditor :global(.quill .ql-snow.ql-toolbar button:focus .ql-stroke),
.textEditor :global(.quill .ql-snow .ql-toolbar button:focus .ql-stroke),
.textEditor :global(.quill .ql-snow.ql-toolbar button.ql-active .ql-stroke),
.textEditor :global(.quill .ql-snow .ql-toolbar button.ql-active .ql-stroke),
.textEditor :global(.quill .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke),
.textEditor :global(.quill .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke),
.textEditor :global(.quill .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke),
.textEditor :global(.quill .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke),
.textEditor :global(.quill .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke),
.textEditor :global(.quill .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke),
.textEditor :global(.quill .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke),
.textEditor :global(.quill .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke),
.textEditor :global(.quill .ql-snow.ql-toolbar button:hover .ql-stroke-miter),
.textEditor :global(.quill .ql-snow .ql-toolbar button:hover .ql-stroke-miter),
.textEditor :global(.quill .ql-snow.ql-toolbar button:focus .ql-stroke-miter),
.textEditor :global(.quill .ql-snow .ql-toolbar button:focus .ql-stroke-miter),
.textEditor :global(.quill .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter),
.textEditor :global(.quill .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter),
.textEditor :global(.quill .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter),
.textEditor :global(.quill .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter),
.textEditor :global(.quill .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter),
.textEditor :global(.quill .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter),
.textEditor :global(.quill .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter),
.textEditor :global(.quill .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter),
.textEditor :global(.quill .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter),
.textEditor :global(.quill .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter) {
	color: var(--main-color);
}

.textEditor :global(.quill .ql-toolbar.ql-snow .ql-formats) {
	margin-right: 0;
}

.textEditor :global(.quill .ql-editor) {
	text-align: right;
	background-color: #ffff;
	font-size: 18px;
	font-weight: 400;
}

.textEditor :global(.quill .ql-toolbar.ql-snow) {
	font-family: "Tajawal", sans-serif !important;
	padding: 10px 8px;
	border-radius: 5px 5px 0 0;
}

.textEditor :global(.quill .ql-editor.ql-blank::before) {
	font-size: 18px;
	font-weight: 400;
	font-style: normal;
}

.textEditor :global(.quill .ql-snow .ql-tooltip) {
	width: 400px;
	white-space: normal;
	gap: 5px;
}

.textEditor :global(.quill .ql-editor li:not(.ql-direction-rtl)::before) {
	margin-left: 0;
	margin-right: 0;
	text-align: right;
}

.textEditor :global(.quill .ql-snow .ql-tooltip input[type="text"]) {
	width: 100%;
	font-size: 14px;
	height: 36px;
	margin-bottom: 6px;
	direction: ltr;
	padding: 3px 10px;
}

.textEditor :global(.quill .ql-snow .ql-tooltip.ql-flip) {
	left: unset !important;
	max-width: 100%;
}

.textEditor :global(.quill .ql-snow .ql-tooltip.ql-editing a.ql-action::after) {
	content: "حفظ";
}

.textEditor :global(.quill .ql-snow .ql-tooltip[data-mode="link"]::before) {
	content: "ادخل الرابط";
}

.textEditor :global(.quill .ql-snow .ql-tooltip a) {
	background: var(--main-color);
	width: 100%;
	padding: 5px;
	display: flex;
	justify-content: center;
	margin-bottom: 5px;
	color: #ffffff;
}

.textEditor :global(.quill .ql-snow .ql-tooltip::before) {
	content: " الرابط الحالي (اضغط علي الرابط للمعاينة)";
	line-height: 26px;
	margin-right: 8px;
}

.textEditor :global(.quill .ql-snow .ql-tooltip a.ql-preview) {
	display: inline-block;
	max-width: 100%;
	overflow-x: hidden;
	text-overflow: ellipsis;
	vertical-align: top;
	background: transparent;
	color: var(--main-color);
	border: 1px solid #ddd;
}

.textEditor :global(.quill .ql-snow .ql-tooltip a.ql-action::after) {
	border-right: none !important;
	content: "تعديل";
	margin-left: 16px;
	padding-right: 8px;
}

.textEditor :global(.quill .ql-snow .ql-tooltip a.ql-remove::before) {
	content: "حذف";
	margin-left: 8px;
}